
import { Component, Vue, Watch } from "vue-property-decorator"
import {
  MyMissionsQuery,
  MyMissionsQueryQuery,
  MyMissionsQueryQueryVariables,
  MissionLifecycleBasicFragmentFragment,
  MissionLead,
  MissionLifecycleState,
  ConsultantProfileFragmentFragment,
} from "@/gql"
import AppPage from "@/components/layout/AppPage.vue"
import AppMetricCard from "@/components/talent/AppMetricCard.vue"
import FilterBar, { FilterType } from "@/components/widgets/common/FilterBar.vue"
import Loader from "@/components/widgets/common/Loader.vue"
import RecordSummaryCard from "@/components/content/RecordSummaryCard.vue"
import PaymentSummaryCard from "@/components/content/PaymentSummaryCard.vue"
import WalletCard from "@/components/content/WalletCard.vue"
import RecordSummaryCardGroup from "@/components/content/RecordSummaryCardGroup.vue"
import ConsultantActivityItem from "@/components/widgets/timeline/ConsultantActivityItem.vue"
import ConsultantMissionActivityTimeline from "@/components/consultant/ConsultantMissionActivityTimeline.vue"
import ActivityTimeline from "@/components/widgets/timeline/ActivityTimeline.vue"
import EditConsultantInvoiceForm from "@/components/forms/EditConsultantInvoiceForm.vue"
import moment from "moment"
import { Maybe } from "graphql/jsutils/Maybe"
import { useProfile } from "@/hooks/useProfile"
import Pagination from "@/components/widgets/common/Pagination.vue"

enum TabContent {
  InProgress,
  Completed,
}

@Component({
  components: {
    AppPage,
    AppMetricCard,
    FilterBar,
    RecordSummaryCard,
    PaymentSummaryCard,
    RecordSummaryCardGroup,
    Loader,
    ConsultantActivityItem,
    ConsultantMissionActivityTimeline,
    ActivityTimeline,
    WalletCard,
    EditConsultantInvoiceForm,
    Pagination,
  },
})
export default class MyMissions extends Vue {
  showAppPage = true
  errorLoading = false
  networkError = false

  filter: FilterType = {}
  filterTab = 0
  missionTab = 0

  executingMissionsPageCount = 0
  executingMissionsCount = 0
  completedMissionsPageCount = 0
  completedMissionsCount = 0

  missionMetrics = [
    {
      title: "All",
      value: 100,
      change: 25,
      unit: "%",
    },
    {
      title: "Executing",
      value: 37,
      change: -33,
      unit: "%",
    },
    {
      title: "Completed",
      value: 72,
      change: 25,
      unit: "%",
    },
    {
      title: "Missions Completion Rate",
      value: "81%",
      change: -33,
      unit: "%",
    },
  ]

  executingMissions: MissionLifecycleBasicFragmentFragment[] = []
  completedMissions: MissionLifecycleBasicFragmentFragment[] = []

  selectedExecutingMission: MissionLifecycleBasicFragmentFragment | null = null
  selectedCompletedMission: MissionLifecycleBasicFragmentFragment | null = null
  activeMission: MissionLifecycleBasicFragmentFragment | null = null
  loadingExecutingMission = false
  loadingCompletedMission = false

  executingMissionPagination = { page: 1, per: 10, length: 1 }
  completedMissionPagination = { page: 1, per: 10, length: 1 }

  showCreateInvoiceForm = false
  invoiceMetadata: Record<string, any> | null = null

  //Mounted
  mounted() {
    this.getExecutingMissions()
    this.profile
  }

  openCreateInvoiceForm() {
    if (!this.profile) return
    if (this.activeMission) {
      this.showCreateInvoiceForm = true

      this.invoiceMetadata = {
        consultantId: this.profile.id,
        missionLifecycleId: this.activeMission.missionStaffingPlacement!.missionLifecycle.id,
        missionStaffingPlacementId: this.activeMission.missionStaffingPlacement!.id,
      }
    }
  }
  //functions
  selectFirstExecutingMission() {
    if (this.executingMissions.length) {
      this.selectedExecutingMission = this.executingMissions[0]
      this.activeMission = this.executingMissions[0]
    }
  }

  selectFirstCompletedMission() {
    if (this.completedMissions.length) {
      this.selectedCompletedMission = this.completedMissions[0]
      this.activeMission = this.completedMissions[0]
    }
  }

  SummaryCardTagGenIcons(missionLead: MissionLead, date: string, type: string) {
    return [
      {
        name: missionLead?.practiceGroup?.name,
        icon: "la-briefcase",
      },
      {
        name: missionLead?.projectLocationCountry,
        icon: "la-map-marker",
      },
      {
        name:
          type === "completed"
            ? "Completed: " + moment(date).format("Do MMMM, YYYY")
            : "Published: " + moment(date).format("Do MMMM, YYYY"),
        icon: "la-calendar",
      },
    ]
  }

  getSelectTagGenIcons(missionLead: MissionLead) {
    const tags = [
      {
        name: missionLead?.practiceGroup?.name,
        icon: "la-briefcase",
      },
      {
        name: missionLead?.projectLocationCountry,
        icon: "la-map-marker",
      },
    ]
    return tags
  }

  getSelectDatesTagGenIcons(missionLead: MissionLead) {
    const tags = [
      {
        name: "Start Date: " + moment(missionLead.tentativeStartDate).format("Do MMMM, YYYY"),
        icon: "la-calendar-day",
      },
      {
        name: "Conclusion Date: " + moment(missionLead.tentativeEndDate).format("Do MMMM, YYYY"),
        icon: "la-calendar-check",
      },
    ]
    return tags
  }

  setActiveExecutingMission(position: MissionLifecycleBasicFragmentFragment) {
    this.selectedExecutingMission = position
    this.activeMission = this.selectedExecutingMission
  }

  setActiveCompletedMission(position: MissionLifecycleBasicFragmentFragment) {
    this.selectedCompletedMission = position
    this.activeMission = this.selectedCompletedMission
  }

  timeAgo(date: Date | string) {
    return moment(date).fromNow()
  }

  async fetchData() {
    if (this.filterTab === TabContent.InProgress) {
      await this.getExecutingMissions()
      this.selectFirstExecutingMission()
      return
    }
    if (this.filterTab === TabContent.Completed) {
      await this.getCompletedMissions()
      this.selectFirstCompletedMission()
      return
    }
  }

  async getExecutingMissions() {
    try {
      this.loadingExecutingMission = true
      const result = await this.$apollo.query<MyMissionsQueryQuery, MyMissionsQueryQueryVariables>({
        query: MyMissionsQuery,
        variables: this.executingMissionQueryVariables,
      })

      if (result && result.data) {
        if (result.data.myMissions.data) {
          this.executingMissions = result.data.myMissions.data

          this.executingMissionsCount = result.data.myMissions.pagination.totalSize || 0

          this.executingMissionsPageCount += result.data.myMissions.pagination.pageCount || 0
        }
      }
      if (result && result.errors) {
        this.showAppPage = false
        this.errorLoading = true
      }
    } catch (error) {
      this.showAppPage = false
      this.networkError = true
    } finally {
      this.loadingExecutingMission = false
      this.selectFirstExecutingMission()
    }
  }

  async getCompletedMissions() {
    try {
      this.loadingCompletedMission = true
      const result = await this.$apollo.query<MyMissionsQueryQuery, MyMissionsQueryQueryVariables>({
        query: MyMissionsQuery,
        variables: this.completedMissionQueryVariables,
      })

      if (result && result.data) {
        if (result.data.myMissions.data) {
          this.completedMissions = result.data.myMissions.data

          this.completedMissionsCount = result.data.myMissions.pagination?.totalSize || 0

          this.completedMissionsPageCount += result.data.myMissions.pagination?.pageCount || 0
        }
      }
      if (result && result.errors) {
        this.showAppPage = false
        this.errorLoading = true
      }
    } catch (error) {
      this.showAppPage = false
      this.networkError = true
    } finally {
      this.loadingCompletedMission = false
      this.selectFirstCompletedMission()
    }
  }

  executingPaginationOptions() {
    return {
      page: this.executingMissionPagination.page,
      per: this.executingMissionPagination.per,
    }
  }

  completedPaginationOptions() {
    return {
      page: this.completedMissionPagination.page,
      per: this.completedMissionPagination.per,
    }
  }

  paginationExecutingLength() {
    this.executingMissionPagination.length =
      Math.round(this.executingMissionsCount / this.executingMissionPagination.per) > 0
        ? Math.round(this.executingMissionsCount / this.executingMissionPagination.per)
        : 1
  }

  paginationCompletedLength() {
    this.completedMissionPagination.length =
      Math.round(this.completedMissionsCount / this.completedMissionPagination.per) > 0
        ? Math.round(this.completedMissionsCount / this.completedMissionPagination.per)
        : 1
  }

  getMissionState(status: string) {
    const states: { [key: string]: string } = { execution_stage: "in_progress" }
    return states[status] || status
  }

  //getters
  get executingMissionQueryVariables() {
    return {
      filter: { ...this.executingQueryFilter },
      ...this.executingMissionPagination,
    }
  }

  get completedMissionQueryVariables() {
    return {
      filter: { ...this.completedQueryFilter },
      ...this.completedMissionPagination,
    }
  }

  get executingQueryFilter() {
    return {
      search: this.filter.search,
      createdAtDate: this.filter.createdAtDate,
      state: MissionLifecycleState.ExecutionStage,
    }
  }

  get completedQueryFilter() {
    return {
      search: this.filter.search,
      createdAtDate: this.filter.createdAtDate,
      state: MissionLifecycleState.Completed,
    }
  }

  //watchers
  @Watch("filterTab")
  async onTabChange() {
    if (this.filter && (this.filter.search || this.filter.createdAtDate)) {
      this.resetFilter()
    }

    await this.fetchData()
  }

  resetFilter() {
    this.$refs.filterBar && (this.$refs.filterBar as FilterBar).reset()
  }

  @Watch("filter")
  onFilterChange() {
    this.debounceCall(() => {
      this.fetchData()
    })
  }

  @Watch("executingMissionPagination.page")
  onExecutingPageChange() {
    this.getExecutingMissions()
  }

  @Watch("executingMissionPagination.per")
  onExecutingPerChange() {
    this.paginationExecutingLength()
    this.getExecutingMissions()
  }

  @Watch("completedMissionPagination.page")
  onCompletedPageChange() {
    this.getCompletedMissions()
  }

  @Watch("completedMissionPagination.per")
  onCompletedPerChange() {
    this.paginationCompletedLength()
    this.getCompletedMissions()
  }

  get profile(): Maybe<ConsultantProfileFragmentFragment> {
    const { profile } = useProfile()
    return profile
  }
}
