var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"consultant-missions-view"}},[(_vm.showAppPage)?_c('AppPage',{attrs:{"title":'My Missions'},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('p',{staticClass:"grey--text"},[_vm._v("View all of your missions and their stages here")])]},proxy:true}],null,false,743496195)},[(false)?_c('section',{staticClass:"my-mission-metrics py-4 mb-2"},[_c('div',{staticClass:"d-flex align-center"},_vm._l((_vm.missionMetrics),function(metric,idx){return _c('AppMetricCard',{key:idx,attrs:{"metric":metric}})}),1)]):_vm._e(),_c('section',{staticClass:"my-missions-wrap mt-5"},[_c('v-row',{staticClass:"main-row",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-0 my-mission-filter-wrap",staticStyle:{"min-height":"100px"},attrs:{"cols":"12","lg":"6"}},[_c('v-card',{staticClass:"my-missions-filter-card pa-4 outlined-card"},[_c('div',{staticClass:"my-mission-filter d-flex align-center ma-0",staticStyle:{"width":"100%"}},[_c('filter-bar',{ref:"filterBar",attrs:{"flat":true,"isBordered":true,"sticky":false,"reverse":false,"searchFilters":[
                  {
                    field: 'search',
                    label: 'Roles',
                    expanded: true,
                  },
                ],"dateFilters":[
                  {
                    field: 'createdAtDate',
                    label: 'Date',
                  },
                ]},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-tabs',{staticClass:"my-missions-filter-tabs mt-1",model:{value:(_vm.filterTab),callback:function ($$v) {_vm.filterTab=$$v},expression:"filterTab"}},[_c('v-tab',{staticClass:"tab-header"},[_c('span',[_vm._v("In Progress")])]),_c('v-tab',{staticClass:"tab-header"},[_c('span',[_vm._v("Completed")])])],1),_c('v-tabs-items',{model:{value:(_vm.filterTab),callback:function ($$v) {_vm.filterTab=$$v},expression:"filterTab"}},[_c('v-tab-item',[_c('section',{staticClass:"my-missions-tab-executing main-content mt-4"},[_c('RecordSummaryCardGroup',{attrs:{"filter":_vm.filter},scopedSlots:_vm._u([{key:"loading-panel",fn:function(){return [_c('div',{staticClass:"text-center grey--text text--darken-2 pa-8"},[(_vm.loadingExecutingMission)?_c('Loader'):(!_vm.executingMissions.length)?_c('div',[_c('span',[_vm._v("Please try adjusting your search keywords or filters.")]),_c('header2',{staticClass:"d-block"},[_vm._v("No missions found")])],1):_vm._e()],1)]},proxy:true}],null,false,1845401371)},_vm._l((_vm.executingMissions),function(mission){return _c('div',{key:mission.id,on:{"click":function($event){return _vm.setActiveExecutingMission(mission)}}},[_c('RecordSummaryCard',{staticClass:"mb-2",attrs:{"title":mission.name,"subtitle":mission.missionStaffingPlacement.missionStaffingPosition.name,"description":mission.missionStaffingPlacement.missionStaffingPosition.description,"tags":_vm.SummaryCardTagGenIcons(
                            mission.missionLead,
                            mission.createdAt,
                            'executing'
                          ),"active":_vm.selectedExecutingMission && _vm.selectedExecutingMission.id === mission.id},scopedSlots:_vm._u([{key:"time-ago",fn:function(){return [_c('div',{staticClass:"mt-1"},[_c('span',{staticStyle:{"font-weight":"400","font-size":"14px","line-height":"21px","color":"#8c8c8c"}},[_vm._v(" "+_vm._s(_vm.timeAgo(new Date(mission.createdAt)))+" ")])])]},proxy:true}],null,true)})],1)}),0),_c('Pagination',{model:{value:(_vm.executingMissionPagination),callback:function ($$v) {_vm.executingMissionPagination=$$v},expression:"executingMissionPagination"}})],1)]),_c('v-tab-item',[_c('section',{staticClass:"my-missions-tab-completed main-content mt-4"},[_c('RecordSummaryCardGroup',{attrs:{"filter":_vm.filter},scopedSlots:_vm._u([{key:"loading-panel",fn:function(){return [_c('div',{staticClass:"text-center grey--text text--darken-2 pa-8"},[(_vm.loadingCompletedMission)?_c('Loader'):(!_vm.completedMissions.length)?_c('div',[_c('span',[_vm._v("Please try adjusting your search keywords or filters.")]),_c('header2',{staticClass:"d-block"},[_vm._v("No missions found")])],1):_vm._e()],1)]},proxy:true}],null,false,657655611)},_vm._l((_vm.completedMissions),function(mission){return _c('div',{key:mission.id,on:{"click":function($event){return _vm.setActiveCompletedMission(mission)}}},[_c('RecordSummaryCard',{staticClass:"mb-2",attrs:{"title":mission.name,"subtitle":mission.missionStaffingPlacement.missionStaffingPosition.name,"description":mission.missionStaffingPlacement.missionStaffingPosition.description,"tags":_vm.SummaryCardTagGenIcons(
                            mission.missionLead,
                            mission.createdAt,
                            'completed'
                          ),"active":_vm.selectedCompletedMission && _vm.selectedCompletedMission.id === mission.id},scopedSlots:_vm._u([{key:"time-ago",fn:function(){return [_c('div',{staticClass:"mt-1"},[_c('span',{staticStyle:{"font-weight":"400","font-size":"14px","line-height":"21px","color":"#8c8c8c"}},[_vm._v(" "+_vm._s(_vm.timeAgo(new Date(mission.createdAt)))+" ")])])]},proxy:true}],null,true)})],1)}),0),_c('Pagination',{model:{value:(_vm.completedMissionPagination),callback:function ($$v) {_vm.completedMissionPagination=$$v},expression:"completedMissionPagination"}})],1)])],1)],1)],1),_c('v-col',{staticClass:"selected-mission pa-0 mt-5 mt-lg-0 pl-lg-3",attrs:{"cols":"12","lg":"6"}},[(_vm.activeMission)?_c('v-card',{staticClass:"selected-mission-card rounded-xl pa-4 pt-1 outlined-card",staticStyle:{"position":"sticky","top":"0px"},attrs:{"flat":""}},[_c('v-tabs',{staticClass:"selected-mission-tabs mt-1",model:{value:(_vm.missionTab),callback:function ($$v) {_vm.missionTab=$$v},expression:"missionTab"}},[_c('v-tab',{staticClass:"tab-header"},[_c('span',[_vm._v("Details")])]),_c('v-tab',{staticClass:"tab-header"},[_c('span',[_vm._v("Payments")])])],1),_c('v-tabs-items',{model:{value:(_vm.missionTab),callback:function ($$v) {_vm.missionTab=$$v},expression:"missionTab"}},[_c('v-tab-item',[_c('section',{staticClass:"selected-mission-tab-details main-content mt-4"},[_c('v-row',{staticClass:"selected-mission-details-header mt-1"},[_c('v-col',{staticStyle:{"padding-bottom":"0px","padding-top":"0px"},attrs:{"cols":"12","md":"4"}},[_c('h2',{staticStyle:{"font-weight":"700","font-size":"18px","line-height":"28px","color":"#0b0101"}},[_vm._v(" "+_vm._s(_vm.activeMission.name)+" ")]),_c('div',{staticClass:"d-flex align-center"},[(
                            _vm.activeMission.missionStaffingPlacement &&
                            _vm.activeMission.missionStaffingPlacement.missionStaffingPosition &&
                            _vm.activeMission.missionStaffingPlacement.missionStaffingPosition.name
                          )?_c('p',{staticStyle:{"font-weight":"400","font-size":"14px","line-height":"21px","color":"#ff0008"}},[_vm._v(" "+_vm._s(_vm.activeMission.missionStaffingPlacement.missionStaffingPosition.name)+" ")]):_vm._e()])]),(_vm.activeMission.state)?_c('v-col',{staticStyle:{"padding-bottom":"0px","padding-top":"0px"},attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"d-flex justify-lg-end align-center"},[_c('StateChip',{attrs:{"state":_vm.getMissionState(_vm.activeMission.state)}})],1)]):_vm._e()],1),_c('div',{staticClass:"selected-mission-details-description mt-4"},[_c('ExpandableTextBlock',{attrs:{"limit":650}},[_vm._v(" "+_vm._s(_vm.activeMission.missionStaffingPlacement.missionStaffingPosition.description)+" ")])],1),(_vm.activeMission.missionLead)?_c('div',{staticClass:"selected-mission-details-tags mt-4 mb-4"},[_c('v-row',{staticClass:"d-flex mid-row"},_vm._l((_vm.getSelectTagGenIcons(_vm.activeMission.missionLead)),function(tag,index){return _c('div',{key:index,staticStyle:{"padding":"0px 2px"}},[_c('div',{staticClass:"pa-2"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":'#CCCCCC'}},[_vm._v(_vm._s(tag.icon))]),_c('span',{staticClass:"tag-name",style:({ color: '#0B0101' })},[_vm._v(_vm._s(tag.name))])],1)])}),0)],1):_vm._e(),(_vm.activeMission.missionLead)?_c('div',{staticClass:"selected-mission-details-dates mt-5"},[_c('header3',[_vm._v("Mission Dates")]),_c('div',{staticClass:"selected-mission-details-dates-tags mt-2"},[_c('v-row',{staticClass:"d-flex mid-row"},_vm._l((_vm.getSelectDatesTagGenIcons(
                            _vm.activeMission.missionLead
                          )),function(tag,index){return _c('div',{key:index,staticStyle:{"padding":"0px 2px"}},[_c('div',{staticClass:"pa-2"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":'#8C8C8C'}},[_vm._v(_vm._s(tag.icon))]),_c('span',{staticClass:"tag-name",style:({ color: '#383838' })},[_vm._v(_vm._s(tag.name))])],1)])}),0)],1)],1):_vm._e(),_c('div',{staticClass:"selected-mission-details-milestones mt-5"},[_c('header3',[_vm._v("Milestones")]),(
                        _vm.activeMission.missionStaffingPlacement &&
                        _vm.activeMission.missionStaffingPlacement.missionStaffingPosition &&
                        _vm.activeMission.missionStaffingPlacement.missionStaffingPosition
                          .missionStaffingMilestones
                      )?_c('div',{staticClass:"selected-mission-details-milestones-card mt-2"},_vm._l((this.activeMission.missionStaffingPlacement
                          .missionStaffingPosition.missionStaffingMilestones),function(milestone,index){return _c('v-card',{key:index,staticClass:"mb-2",attrs:{"outlined":""}},[_c('v-row',{staticStyle:{"padding":"10px 0px"}},[_c('v-col',{staticClass:"align-content-md-center",attrs:{"cols":"12","md":"10"}},[_c('ExpandableTextBlock',{staticClass:"ml-2",staticStyle:{"font-size":"14px","color":"#0b0101"},attrs:{"limit":100}},[_vm._v(" "+_vm._s(milestone.description)+" ")])],1),(milestone.fees)?_c('v-col',{staticClass:"d-flex justify-md-end align-items-md-center",attrs:{"cols":"12","md":"2"}},[_c('p',{staticClass:"mr-md-2 mb-0",staticStyle:{"font-size":"14px","color":"#ff0008","margin-left":"8px"}},[_vm._v(" USD"+_vm._s(milestone.fees)+" ")])]):_vm._e()],1)],1)}),1):_vm._e(),(
                        !_vm.activeMission.missionStaffingPlacement.missionStaffingPosition
                          .missionStaffingMilestones.length
                      )?_c('div',{staticClass:"selected-mission-details-milestones-not-found mt-2"},[_c('span',{staticClass:"d-block"},[_vm._v("No milestones found in this mission")])]):_vm._e()],1),(_vm.activeMission.activityLogs)?_c('div',{staticClass:"selected-mission-details-activity mt-5"},[_c('header3',[_vm._v("Activity")]),_c('div',{staticClass:"selected-mission-details-activity-card mt-2"},[_c('ConsultantMissionActivityTimeline',{attrs:{"logs":_vm.activeMission.activityLogs}})],1)],1):_vm._e()],1)]),_c('v-tab-item',[_c('section',{staticClass:"selected-mission-tab-payments main-content mt-4"},[(false)?_c('div',{staticClass:"selected-mission-payments-mission-wallet mt-5"},[_c('header3',[_vm._v("Mission Wallet")]),_c('div',{staticClass:"selected-mission-payments-mission-wallet-card mt-2"},[_c('WalletCard')],1)],1):_vm._e(),_c('div',{staticClass:"selected-mission-payments-mission-payments mt-5"},[_c('div',{staticClass:"d-flex justify-between"},[_c('header3',[_vm._v("Payments")]),_c('SecondaryButton',{on:{"click":_vm.openCreateInvoiceForm}},[_vm._v("New Invoice")])],1),(
                        _vm.activeMission.missionStaffingPlacement &&
                        _vm.activeMission.missionStaffingPlacement.consultantInvoices
                      )?_c('div',{staticClass:"selected-mission-payments-mission-payments mt-2"},_vm._l((_vm.activeMission.missionStaffingPlacement
                          .consultantInvoices),function(payment){return _c('PaymentSummaryCard',{key:payment.id,staticClass:"mb-2",attrs:{"payment":payment}})}),1):_vm._e(),(!_vm.activeMission.missionStaffingPlacement.consultantInvoices.length)?_c('div',[_c('span',{staticClass:"d-block"},[_vm._v("No payments found in this mission")])]):_vm._e()])])])],1)],1):_vm._e()],1)],1)],1),_c('FormDialog',{attrs:{"label":"Edit Invoice"},model:{value:(_vm.showCreateInvoiceForm),callback:function ($$v) {_vm.showCreateInvoiceForm=$$v},expression:"showCreateInvoiceForm"}},[_c('EditConsultantInvoiceForm',{attrs:{"metadata":_vm.invoiceMetadata},on:{"save":function($event){_vm.showCreateInvoiceForm = false}}})],1)],1):(_vm.errorLoading)?_c('FeedBackMessage',{attrs:{"resource":"myMissions","preset":"loadingError","buttonOnClick":_vm.fetch}}):(_vm.networkError)?_c('FeedBackMessage',{attrs:{"resource":"myMissions","preset":"networkError"}}):_c('FeedBackMessage',{attrs:{"resource":"myMissions","preset":"notFound"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }