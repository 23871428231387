
import { Component, Prop, Vue } from "vue-property-decorator"
import { ConsultantInvoice } from "@/gql"
import moment from "moment"
@Component
export default class PaymentSummaryCard extends Vue {
  @Prop({ required: true }) readonly payment!: ConsultantInvoice

  get PaymentTagGenIcons() {
    return [
      {
        name: "Created: " + moment(this.payment.createdAt).format("Do MMMM, YYYY"),
        icon: "la-calendar-day",
      },
      {
        name: "Due: " + moment(this.payment.dueAt).format("Do MMMM, YYYY"),
        icon: "la-calendar-check",
      },
    ]
  }

  gotoDetailPage(invoice: ConsultantInvoice) {
    this.$router.push({
      name: this.$routes.InvoiceDetail,
      params: { id: invoice.id.toString() },
    })
  }
}
